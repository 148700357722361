import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout/Layout";
import SupportButtons from "../modules/SupportButtons";
import { mediaQuery } from "../components/_breakpoints";
import bulb from "../img/bulb.svg";
import bckgdImg from "../img/shapeBackground.svg";
import { colorsGlobal } from "../components/_global";
import IFrame from "../components/IFrame";

export const IndexPageTemplate = ({ title, subtitle, quotes, data }) => {
  const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
  return (
    <IndexWrapper>
      <Section>
        <TopSection>
          <ShapeWrapper>
            <Bulb src={bulb} />
            <BackgroundFloatingImg src={bckgdImg} />
          </ShapeWrapper>
          <MainText>
            <Heading>„{randomQuote.quoteText}”</Heading>
            {randomQuote.author && (
              <Text>
                <br />-{" "}
                {randomQuote.source ? <i>„{randomQuote.source}”, </i> : ""}
                {randomQuote.author}
                {randomQuote.year ? `, ${randomQuote.year}` : ""}
              </Text>
            )}
          </MainText>
        </TopSection>
        <YoutubeLinksWrapper>
          <FilmBox>
            {data.linkToFilmWojnaIdei && (
              <IFrameWrapper>
                <Strong
                  color={colorsGlobal.primary}
                  to={"/films?channel=wojna-idei"}
                  mr
                >
                  Wojna Idei
                </Strong>
                <IFrame
                  src={`https://www.youtube.com/embed/${data.linkToFilmWojnaIdei}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media;
          gyroscope; picture-in-picture"
                  allowFullScreen={true}
                />
              </IFrameWrapper>
            )}
            {data.linkToFilmSzymonMowi && (
              <IFrameWrapper>
                <Strong
                  color={colorsGlobal.secondary}
                  to={"/films?channel=szymon-mowi"}
                  ml
                >
                  Szymon Mówi
                </Strong>
                <IFrame
                  src={`https://www.youtube.com/embed/${data.linkToFilmSzymonMowi}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media;
          gyroscope; picture-in-picture"
                  allowFullScreen={true}
                />
              </IFrameWrapper>
            )}
          </FilmBox>
        </YoutubeLinksWrapper>
      </Section>
      <SocialButtonsWrapper>
        <SupportButtons />
        {data.patrons && data.patrons.length > 0 && (
          <Patrons>
            <PatronsTitle>Główni patroni:</PatronsTitle>
            {data.patrons.map((el) => (
              <SinglePatron>{el.name}</SinglePatron>
            ))}
          </Patrons>
        )}
      </SocialButtonsWrapper>
    </IndexWrapper>
  );
};

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const ytFilmsWI = data.wojnaIdeiYt.data.items;
  const ytFilmsSM = data.szymonMowiYt.data.items;
  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        data={{
          patrons: frontmatter.patrons,
          linkToFilmWojnaIdei: ytFilmsWI[0].id.videoId,
          linkToFilmSzymonMowi: ytFilmsSM[0].id.videoId,
        }}
        quotes={frontmatter.quotes}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

const IndexWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  ${mediaQuery.sm(`
    padding-bottom: 20px;
  `)}
`;

const Section = styled.div`
  display: flex;
  order: 1;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 100%;
  ${mediaQuery.sm(`
    width: 100%;
    justify-content: flex-start;
    align-items: left;
    height: 100%;
    text-align: left;
    top: 25px;
  `)}
  ${mediaQuery.xl`
    top: 50px;
  `}
`;

const SocialButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  order: 3;
  z-index: 999;
  flex-direction: column;
  ${mediaQuery.sm(`
    position: absolute;
    width: 800px;
    justify-content: flex-end;
    top: 0;
    right: 2.5%;
    order: 2;
  `)}
  ${mediaQuery.xl(`
    right: 6.5%;
  `)}
`;

const Patrons = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  left: 20px;
  margin-bottom: 20px;
  ${mediaQuery.sm(`
      left: auto;
      align-items: flex-end;
      margin-bottom: 0;
  `)}
  ${mediaQuery.xl(`
    right: -70px;
  `)}
`;

const PatronsTitle = styled.div`
  display: flex;
  font-size: 20px;
  font-weight: bold;
`;

const SinglePatron = styled.div`
  display: flex;
`;

const ShapeWrapper = styled.div`
  display: none;
  position: absolute;
  left: -13%;
  top: -15%;
`;

const FilmBox = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  width: 100%;
  ${mediaQuery.sm(`
    flex-direction: row;
  `)}
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-top: 50px;
  position: relative;
  z-index: 50;
  flex: 2;
  ${mediaQuery.sm`
    display: flex;
  `}
`;

const YoutubeLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
  margin: auto;
  margin-top: 30px;
  z-index: 100;
  ${mediaQuery.sm`
      width: 100%;
      margin-top: 10px;
      padding-bottom: 150px;
  `}
`;

const IFrameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 193px;
  width: 100%;
  min-width: 150px;
  flex-shrink: 1;
  margin: 10px 0 25px;
  ${mediaQuery.sm(`
    height: 100%;
    max-height: 267px;
    max-width: 400px;
      margin: 0;
  `)}
  @media only screen and (min-width: 1340px) {
    max-height: 300px;
    max-width: 450px;
  }
  @media only screen and (min-width: 1500px) {
    max-height: 330px;
    max-width: 480px;
  }
  @media only screen and (min-width: 1740px) {
    max-height: 350px;
    max-width: 600px;
  }
`;

const BackgroundFloatingImg = styled.img`
  display: flex;
  opacity: 0.7;
  position: absolute;
  width: 200px;
  margin-top: 0;
  ${mediaQuery.sm`
    width: 300px;
  `}
  ${mediaQuery.xxl`
    width: 500px;
  `}
`;

const Bulb = styled.img`
  display: flex;
  opacity: 1;
  position: relative;
  width: 200px;
  z-index: 1;
  left: 90px;
  top: 50px;
  ${mediaQuery.sm`
    width: 200px;
  `}
  ${mediaQuery.xxl`
    width: 400px;
  `}
`;

const MainText = styled.span`
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 2% 20px 2%;
  width: 96%;
  margin: 70px auto 0;
  border-left: 5px solid ${colorsGlobal.primary};
  background: #ffffff6f;
  z-index: 2;
  margin-left: 10px;
  ${mediaQuery.sm`
    width: 65%;
    margin: 4% 0;
    margin-left: 0;
  `}
`;

const Strong = styled(Link)`
  text-decoration: none;
  margin: 0 ${(props) => (props.mr ? "4.5px" : 0)} 0
    ${(props) => (props.ml ? "4.5px" : 0)};
  color: ${(props) => props.color || "black"};
  font-weight: bold;
  font-size: 22px;
`;

const Heading = styled.h1`
  display: flex;
  margin-bottom: 10px;
`;

const Text = styled.span`
  display: block;
  font-size: 20px;
`;

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        subtitle
        patrons {
          name
        }
        quotes {
          quoteText
          author
          source
          year
        }
      }
    }
    wojnaIdeiYt {
      data {
        items {
          etag
          id {
            kind
            videoId
            channelId
            playlistId
          }
          kind
          snippet {
            channelId
            channelTitle
            description
            title
          }
        }
      }
    }
    szymonMowiYt {
      data {
        items {
          etag
          id {
            kind
            videoId
            channelId
            playlistId
          }
          kind
          snippet {
            description
            title
          }
        }
      }
    }
  }
`;
