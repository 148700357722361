import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SocialButton from "../../components/ActionButtons/SocialButton";
import { mediaQuery } from "../../components/_breakpoints";
import Portal from "../../components/Portal";
import { socialButtonsColors } from "../../components/_global";
import closeSVG from "../../img/close.svg";
import SocialLink from "../../components/ActionButtons/SocialLink";

const socialButtons = [
  {
    name: "wesprzyj moją działalność",
    id: "wesprzyj",
    nodes: [
      {
        name: "patronite",
        id: "patronite",
        isModal: false,
        link: "https://patronite.pl/wojnaidei",
      },
      {
        name: "paypal",
        id: "paypal",
        isModal: false,
        link: "https://paypal.me/wojnaidei",
      },
      { name: "konto bankowe", id: "kotno", isModal: true, link: "" },
    ],
    link: "",
  },
];

const DirectModalContent = ({ setModalOpen }) => {
  return (
    <Modal color={socialButtonsColors["konto bankowe"]}>
      <CloseButton onClick={() => setModalOpen(false)}>
        <img src={closeSVG} alt="Close" style={{ width: "12px" }} />
      </CloseButton>
      <ModalTitle>Numer konta:</ModalTitle>
      <ModalContent>
        <p>
          <b>59 1140 2004 0000 3402 7617 3625</b>
        </p>
        <p>
          W tytule przelewu proszę wpisać <b>"darowizna"</b>
        </p>
        <p>Dzięki za wsparcie!</p>
      </ModalContent>
    </Modal>
  );
};

const SupportButtons = () => {
  const [isActive, setActive] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeTabElements, setActiveTabElements] = useState({});

  useEffect(() => {
    const newActive = socialButtons.find((el) => el.id === isActive);
    setActiveTabElements(newActive);
  }, [isActive]);

  return (
    <SocialButtonsWrapper>
      {isModalOpen && (
        <Portal id="___gatsby">
          <MobileDirect>
            <DirectModalContent setModalOpen={setModalOpen} />
          </MobileDirect>
        </Portal>
      )}
      <SemiSocialButtonsWrapper isActive={isActive}>
        {activeTabElements?.nodes?.map((el) => {
          return el.link || el.linkTo ? (
            <SemiSocialLink
              {...el}
              href={el.link}
              to={el.linkTo}
              backgroundColor="white"
              isActive={el.isModal && isModalOpen}
              handleClick={(data) =>
                el.isModal && !isModalOpen
                  ? setModalOpen(true)
                  : setModalOpen(false)
              }
            />
          ) : (
            <SemiSocialButton
              {...el}
              backgroundColor="white"
              isActive={el.isModal && isModalOpen}
              handleClick={(data) =>
                el.isModal && !isModalOpen
                  ? setModalOpen(true)
                  : setModalOpen(false)
              }
            />
          );
        })}
        {isModalOpen && isActive === socialButtons[0].id && (
          <DesktopDirect>
            <DirectModalContent setModalOpen={setModalOpen} />
          </DesktopDirect>
        )}
      </SemiSocialButtonsWrapper>
      {socialButtons.map((el) =>
        el.link || el.linkTo ? (
          <SocialLink
            {...el}
            href={el.link}
            to={el.linkTo}
            isActive={el.id === isActive}
            handleClick={(data) =>
              data === isActive ? setActive("") : setActive(data)
            }
          />
        ) : (
          <SocialButton
            {...el}
            isActive={el.id === isActive}
            handleClick={(data) =>
              data === isActive ? setActive("") : setActive(data)
            }
          />
        )
      )}
      <DesktopExtraSocialButtons>
        <SocialLink
          name="SKLEP"
          href="https://zalezy.pl/"
          isActive={"SKLEP" === isActive}
          handleClick={(data) =>
            data === isActive ? setActive("") : setActive(data)
          }
        />
      </DesktopExtraSocialButtons>
    </SocialButtonsWrapper>
  );
};

const Modal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex-direction: column;
  box-sizing: border-box;
  position: fixed;
  background: rgba(255, 255, 255, 1);
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  width: calc(86% - 4px);
  top: calc(50% - 100px);
  left: 7%;
  right: 7%;
  height: 200px;
  z-index: 999;
  border-color: ${(props) => props.color};
  ${mediaQuery.sm(`
    left: 0;
    width: calc(94% - 4px);
  `)}
`;

const SocialButtonStyled = styled(SocialButton)`
  display: flex;
  padding: 5px 10px;
`;

const ModalTitle = styled.h2`
  display: flex;
  font-size: 20px;
  font-family: "Source Sans Pro", sans-serif;
`;

const ModalContent = styled.span`
  display: flex;
  flex-direction: column;
  font-family: "Source Sans Pro", sans-serif;
  p {
    margin: 5px auto;
    text-align: center;
  }
`;

const CloseButton = styled.button`
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  align-self: flex-end;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  width: fit-content;
`;

const SocialButtonsWrapper = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 30px 0;
  margin-top: 0px;
  ${mediaQuery.sm(`
    flex-wrap: no-wrap;
    justify-content: flex-end;
    z-index: 20;
    margin-top: 80px;
    width: auto;
  `)}
  @media only screen and (min-width: 1090px) {
    margin: 30px 0;
  }
  ${mediaQuery.xl(`
    flex-wrap: no-wrap;
    justify-content: flex-end;
    z-index: 20;
    margin: 30px 0;
    margin-right: -80px;
    width: auto;
  `)}
  a {
    margin: 3px;
  }
`;

const SemiSocialButton = styled(SocialButton)`
  font-weight: 700;
  line-height: 20px;
  padding: 5px 10px;
  font-size: 16px;
  margin: 3px;
  ${mediaQuery.sm(`
    font-size: 14px;
height: 100%;
    line-height: 10px;
    padding: 10px 20px;
    width: auto;
  `)}
`;

const SemiSocialLink = styled(SocialLink)`
  font-weight: 700;
  line-height: 20px;
  padding: 5px 10px;
  font-size: 16px;
  margin: 3px;
  ${mediaQuery.sm(`
    font-size: 14px;
    height: 100%;
    line-height: 10px;
    padding: 10px 20px;
    width: auto;
  `)}
`;

const SemiSocialButtonsWrapper = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  transform: translateY(-100%);
  ${mediaQuery.sm(`
    transform: translateY(100%);
    transform: translateX(0);
    right: 0;
    margin-top: 40px;
  `)}
`;

const DesktopDirect = styled.div`
  display: none;
  left: 0;
  transform: translateY(59%);
  width: 400px;
  height: 200px;
  position: absolute;
  ${mediaQuery.sm(`
    display: flex;
  `)}
`;

const MobileDirect = styled.div`
  display: flex;
  ${mediaQuery.sm(`
    display: none;
  `)}
`;

const DesktopExtraSocialButtons = styled.div`
  display: none;
  ${mediaQuery.sm(`
    display: flex;
  `)}
`;

export default SupportButtons;
